import { apiCall, apiCallFormData, objToQueryString } from '..'
import { Api, Constants } from '../../config'
import { objectToFormData } from '../../helpers/arrayHelpers'
const getMemories = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
    postType: Constants.postType.POST_TYPE_MEMORY,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/post?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}

const getMemoryStatistics = (params = {}) => {
  const parameters = {}
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/memoryStatistics?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}

const createMemory = (formData) => {
  const url = `${Api.baseURL}/post`
  return apiCallFormData(url, formData)
}
const likeMemory = (isLike, memoryId) => {
  const apiParam = isLike ? 'like' : 'unlike'
  const body = {
    postId: memoryId,
  }
  const request = {
    url: `${Api.baseURL}/post/${apiParam}`,
    method: 'post',
    body: JSON.stringify(body),
  }
  return apiCall(request)
}

export default {
  getMemories,
  createMemory,
  getMemoryStatistics,
  likeMemory,
}
