import i18next from "i18next";
import MemoryService from "../api/MemoryService";
import loadData from "./data";

export function getStatistics(params = {}) {
  return new Promise((resolve, reject) => {
    MemoryService.getMemoryStatistics(params)
      .then((result) => {
        const ratings = result.data.data.map((item) => {
          return {
            ...item,
            rating: Number(item.rating).toFixed(1),
          };
        });
        resolve(ratings);
      })
      .catch((error) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    MemoryService.getMemories(params)
      .then((result) => {
        resolve({
          data: result.data.data.map((item) => {
            const memory = { ...item };
            if (memory.author) {
              memory.author.username = memory.author.username;
            }
            if (memory.rating) {
              memory.rating = Number(memory.rating).toFixed(1);
            }
            memory.isFavorite = memory.isFavourite == true;

            return memory;
          }),
        });
      })
      .catch((error) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export default {
  find,
  getStatistics,
};
