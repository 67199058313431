import PostService from "../api/PostService";

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    PostService.getPosts(params)
      .then((result) => {
        resolve({
          totalCount: result.data.totalCount,
          communityCount: result.data.communityCount,
          officialCount: result.data.officialCount,
          postCount: result.data.postCount,
          memoryCount: result.data.memoryCount,

          data: result.data.data.map((item) => {
            const post = { ...item };
            if (post.author) {
              post.author.username = post.author.username;
            }
            if (post.rating) {
              post.rating = Number(post.rating).toFixed(1);
            }
            post.isFavorite = post.isFavourite == true;

            return post;
          }),
        });
      })
      .catch((error) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export default {
  find,
};
