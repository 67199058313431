import i18next from 'i18next'
import loadData from './data'

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    const initData = loadData.getData()
    const data = initData.memoryCategories
    resolve(data)
  })
}
export function findOne(params = {}) {
  return new Promise((resolve, reject) => {
    const initData = loadData.getData()
    let item = initData.memories[0]
    resolve(item)
  })
}
export default {
  find,
  findOne,
}
