import { NotificationService } from "../api";

export function getList(params) {
  return new Promise((resolve, reject) => {
    NotificationService.list(params)
      .then((result) => {
        resolve(result?.data);
      })
      .catch((error) => {
        console.error("error", error);
        reject(error);
      });
  });
}
export function getUnreadCount() {
  return new Promise((resolve, reject) => {
    NotificationService.getUnreadNotificationCount()
      .then((result) => {
        console.error("result", result);
        resolve(result?.data?.totalUnreadCount);
      })
      .catch((error) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export default {
  getList,
  getUnreadCount,
};
