import { LocationService } from "../api";
import loadData from "./data";

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    LocationService.getLocations(params).then((result) => {
      const data = result.data.data;
      const worldWide = {
        city_id: null,
        isWorldWide: true,
        name: "World",
      };
      const virtual = {
        city_id: -1,
        isVirtual: true,
        name: "Virtual",
      };
      const list = [];
      if (!params.withoutWorldwideVirtual) {
        list.push(worldWide);
      }
      list.push(
        ...data.map((item) => {
          return {
            ...item,
            name: item.city_name,
            country: item.country_name,
            id: item.city_id,
          };
        })
      );
      if (!params.withoutWorldwideVirtual) {
        list.push(virtual);
      }
      resolve(list);
    });
  });
}
export default {
  find,
};
