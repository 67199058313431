import { apiCall, apiCallFormData, objToQueryString } from '..'
import { Api, Constants } from '../../config'
import { objectToFormData } from '../../helpers/arrayHelpers'
const getPosts = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/post?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}

const createPost = (formData) => {
  const url = `${Api.baseURL}/post`
  return apiCallFormData(url, formData)
}
const likePost = (isLike, memoryId) => {
  const apiParam = isLike ? 'like' : 'unlike'
  const body = {
    postId: memoryId,
  }
  const request = {
    url: `${Api.baseURL}/post/${apiParam}`,
    method: 'post',
    body: JSON.stringify(body),
  }
  return apiCall(request)
}
const sharePost = (id) => {
  const body = {
    postId: id,
  }
  const request = {
    url: `${Api.baseURL}/addSharePost`,
    method: 'post',
    body: JSON.stringify(body),
  }
  return apiCall(request)
}

const getShare = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/sharepost?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}

export default {
  createPost,
  likePost,
  getPosts,
  sharePost,
  getShare,
}
