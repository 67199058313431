import { ProfileConnectService, ProfileService } from '../api'

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    ProfileService.getProfileFind(params)
      .then((result) => {
        resolve(result.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function statistics(params = {}) {
  return new Promise((resolve, reject) => {
    ProfileService.getProfileStatistics(params)
      .then((result) => {
        resolve(result.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function findProfileConnects(params = {}) {
  return new Promise((resolve, reject) => {
    ProfileConnectService.getProfileConnects(params)
      .then((result) => {
        resolve(result.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export function findOne(profileId) {
  return new Promise((resolve, reject) => {
    ProfileService.getProfileByProfileId(profileId).then((result) => {
      resolve(result.data.data)
    })
  })
}
export default {
  find,
  statistics,
  findOne,
  findProfileConnects,
}
