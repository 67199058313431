import { HomeService } from "../api";
import { Constants } from "../config";

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    HomeService.getHomeCategories(params).then((result) => {
      resolve(result.data.data);
    });
  });
}
export function getConstantCategories() {
  return new Promise((resolve, reject) => {
    resolve([
      {
        id: Constants.categoryType.CATEGORY_VENUE,
        title: "Venues",
        slug: "venues",
      },
      {
        id: Constants.categoryType.CATEGORY_FESTIVAL,
        title: "Festivals",
        slug: "festivals",
      },
      {
        id: Constants.categoryType.CATEGORY_ARTIST,
        title: "Artists",
        slug: "artists",
      },
      {
        id: Constants.categoryType.CATEGORY_OTHER,
        title: "Others",
        slug: "others",
      },
    ]);
  });
}
export default {
  find,
  getConstantCategories,
};
